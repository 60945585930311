import {AxiosInstance} from "axios";
import {AccountDTO} from "../lib";

export interface CreateAccountDTO {
    name: string
    emails: string[]
    subscription: string
    keywords: string[]
}

export interface EditAccountDTO {
    name: string
    emails: string[]
    id: string
    keywords: string[]
}

export interface UpdatePartnerDTO {
    link: string
    logo: string
}

export class PartnerAccountAPI {
    static getInstance: (api: AxiosInstance) => PartnerAccountAPI;

    constructor(private api?: AxiosInstance) {
    }

    async addAccount( createAccount: CreateAccountDTO): Promise<AccountDTO> {
        try {
            const response = await this.api.post<AccountDTO>(
                `/partner/accounts`,
                createAccount
            );
            return response.data;
        } catch (error) {
            console.error(error)
            throw new Error(error.response?.data?.message || 'An error occurred');
        }
    }
    async editAccount( account: EditAccountDTO): Promise<AccountDTO> {
        try {
            const response = await this.api.patch<AccountDTO>(
                `/partner/accounts/${account.id}`,
                account
            );
            return response.data;
        } catch (error) {
            console.error(error)
            throw new Error(error.response?.data?.message || 'An error occurred');
        }
    }


    async getAccounts(): Promise<AccountDTO[]> {
        try {
            const response = await this.api.get<AccountDTO[]>(
                `/partner/accounts`
            );
            return response.data;
        } catch (error) {
            console.error(error)
            throw new Error(error.response?.data?.message || 'An error occurred');
        }
    }
    async getAllAccounts(): Promise<AccountDTO[]> {
        try {
            const response = await this.api.get<AccountDTO[]>(
                `/admin/partner/accounts`
            );
            return response.data;
        } catch (error) {
            console.error(error)
            throw new Error(error.response?.data?.message || 'An error occurred');
        }
    }

    async signup( values) {
        try {
            const response = await this.api.post('/public/partner', values);
            return response.data;
        } catch (error) {
            console.error(error)
            throw new Error(error.response?.data?.message || 'An error occurred');
        }
    }
    async forgotPassword(values) {
        try {
            const response = await this.api.patch('/public/partner/forgot-password', values);
            return response.data;
        } catch (error) {
            console.error(error)
            throw new Error(error.response?.data?.message || 'An error occurred');
        }
    }

    async validateEmailCode(email: string) {
        try {
            const response = await this.api.put('/public/partner/validate-email-code', {email});
            return response.data;
        } catch (error) {
            console.error(error)
            throw new Error(error.response?.data?.message || 'An error occurred');
        }
    }


    async forgotPasswordCode(email: string) {
        try {
            const response = await this.api.put('/public/partner/forgot-password-code', {email});
            return response.data;
        } catch (error) {
            console.error(error)
            throw new Error(error.response?.data?.message || 'An error occurred');
        }
    }

    async updatePassword(values) {
        try {
            const response = await this.api.patch('/partner/update-password', values);
            return response.data;
        } catch (error) {
            console.error(error)
            throw new Error(error.response?.data?.message || 'An error occurred');
        }
    }

    async updatePartnerAccount( values: UpdatePartnerDTO) {
        try {
            const response = await this.api.post('/public/partner', values);
            return response.data;
        } catch (error) {
            console.error(error)
            throw new Error(error.response?.data?.message || 'An error occurred');
        }
    }


    async getProtections( accountId) {
        try {
            const response = await this.api.get('/partner/protections', accountId);
            return response.data;
        } catch (error) {
            console.error(error)
            throw new Error(error.response?.data?.message || 'An error occurred');
        }
    }

    async deleteAccount(id: number) {
        try {
            const response = await this.api.delete(`/partner/accounts/${id}`);
            return response.data;
        } catch (error) {
            console.error(error)
            throw new Error(error.response?.data?.message || 'An error occurred');
        }
    }

}

PartnerAccountAPI.getInstance = (api: AxiosInstance) => {
    return new PartnerAccountAPI(api)
}
