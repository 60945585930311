/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/react';
import {Button, Card, Checkbox, Form, Input} from "antd";
import React, {useContext} from "react";
import {LoginImage, UserContext, useUser} from "@front-component";
import {useLoading} from "@front-component";
import {LockOutlined, UserOutlined} from "@ant-design/icons";

const Style = styled.div`
text-align: center;
padding: 4em;
#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
`
export function LoginPage(){
    const userCtx = useContext(UserContext);
    const {doAction, loading} = useLoading()
    const { loginBo } = useUser(userCtx);

    const onFinish = (values: any)=>doAction(async () => {
        userCtx.reset();
        const result: any = await loginBo(values.email, values.password, values.remember);
        setTimeout(() => {window.location.href = "/user/dashboard"}, 100);

    });

    return <Style>
        <br/>
        <LoginImage/>
        <br/>
        <br/>
        <br/>
        <Card css={css`width: 600px;margin: auto`} title="BO Login">
        <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
        >
            <Form.Item
                name="email"
                rules={[{ required: true, message: 'Please input your Username!' }]}
            >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your Password!' }]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                />
            </Form.Item>
            <Form.Item>
                <Button css={css`width: 200px`}
                        type="primary"
                        htmlType="submit"
                        className="login-form-button">
                    Log in
                </Button>
            </Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
            </Form.Item>

        </Form>
        </Card>
    </Style>
}
