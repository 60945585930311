/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {Fragment, useCallback, useContext, useEffect, useState} from "react";
import {AxiosContext, AxiosContextType, getFlag, useLoading} from "@front-component";
import {Button, Popover, Select, Spin} from "antd";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import axios from "axios";
import {ProtectionDTO, ScrapeDTO} from "@backend-lib";

const Style = styled.div`
`


const gridStyle = {minHeight: 550}

export function FreeCompetitorCheckDashboardPage() {
    const {api} = useContext(AxiosContext)
    const [dataSource, setdataSource] = useState(undefined)
    const {doAction, loading} = useLoading()

    const [expandedRows, setExpandedRows] = useState({ 1: true, 2: true });
    const [collapsedRows, setCollapsedRows] = useState(null);
    const onExpandedRowsChange:any = useCallback(({ expandedRows, collapsedRows, data, id, index, rowExpanded }:any) => {
        setExpandedRows(expandedRows);
        setCollapsedRows(collapsedRows);
    }, [])


    const columns = [
        {name: 'createdAt', header: 'Date', defaultWidth:200},
        {name: 'email', header: 'Email', defaultWidth:200},
        {name: 'googleURL', header: 'Google URL', defaultWidth:200, render: ({ value, data }) => <a href={value} target={"_blank"}>{value}</a>},
        {name: 'demoCredit', header: 'Credit', defaultFlex: 1},
        {name: 'rankThreats', header: 'Rank threat', defaultFlex: 1,render: ({ value, data }) => (<Popover
                    content={<ul>{value.map?.((p,i) => <li key={p.id+"_"+i}>{p.competitorAds?.map(c=>c.displayUrl||c.destinationUrl)?.join(',')}</li>)}</ul>}>
                    {value.length}
                    {' '}
                    {value.map?.((p,i) =>(getFlag(p.geoCriterion.countryCode) as any)?.emoji).join(" ")}
                </Popover>
            ) as any,},
        {name: 'rankOks', header: 'Rank ok', defaultFlex: 1,render: ({ value, data }) => (
                <Popover content={<ul>{value.map?.((p,i) => <li key={p.id+"_"+i}>{p.geoCriterion.name}</li>)}</ul>}>
                    {value.length}
                    {' '}
                    {value.map?.((p,i) =>(getFlag(p.geoCriterion.countryCode) as any)?.emoji).join(" ")}
                </Popover>
            ),},
        {name: 'referrals', header: 'Referrals', defaultFlex: 1},
        {
            name: 'keywords', header: 'Keywords', defaultFlex: 3, defaultWidth:150,
            render: ({value, data}: { value: any[], data: ProtectionDTO }) => {

                return <Select mode={"multiple"} css={css`width:100%`}
                               defaultValue={value.map(k => k.text)}>
                    {value.map(k => <Select.Option checked={true} value={k.text}>{k.text}</Select.Option>)}
                </Select>
            }
        },
    ]
    useEffect(() => {doAction(async () => {
        const dashboardData: any = (await api.get("/admin/free-scrap")).data
        setdataSource( dashboardData.map(p=>({
            createdAt:p.createdAt,
            scrape:p.scrapes[0],
            keywords:p.keywordsCriteria,
            email:p.account.users?.[0]?.email,
            demoCredit:p.account.users?.[0]?.demoCredit,
            referrals:p.account.users?.[0]?.referrals?.map(u=>u.email)?.join(','),
            rankThreats: p.scrapes[0]?.rankThreats,
            rankOks: p.scrapes[0]?.rankOks,
            googleURL: p.scrapes[0].scrapResults?.search_metadata.engine_url
        })));
    })}, [])

    if (loading) {
        return <Spin/>
    }

    const renderRowDetails = ({data}) => {
        return <Fragment></Fragment>
      /*  const lastScrape = data.lastScrape
        return <div style={{padding: 20}}>
            {lastScrape.scrapResults.ads.map(ad=>(<Fragment>
                <div css={css`display: flex;gap:1em; align-items:center`}><GoogleLogo/>
                <Search css={css`width:200px`}/><a href={lastScrape.scrapResults.search_metadata.engine_url}>See on Google</a></div>
                <div css={css`display: flex;gap:1em; align-items:center`}><span
                css={css`font-size:12px;font-weight:bold`}>Ad</span><span
                css={css`font-size:10px`}>{ad.displayUrl}</span>
                </div>
                <a css={css`    color: #1a0dab;    font-size: 12px;
    line-height: 26px;`}>{ad.title}</a>
                <div
                css={css`font-size: 10px;`}> {ad.description}</div>
                </Fragment>
            ))}
        </div>*/
    };

    return <Style>
        {dataSource &&
        <ReactDataGrid
            idProperty="id"
            renderRowDetails={renderRowDetails}
            rowExpandColumn={true}
            multiRowExpand={true}
            expandedRows={expandedRows}
            collapsedRows={collapsedRows}
            onExpandedRowsChange={onExpandedRowsChange}
            rowExpandHeight={400}
            style={gridStyle}
            defaultSortInfo={{name:"createdAt",dir:-1}}
            columns={columns as any}
            dataSource={dataSource}
        />
        }
    </Style>

}

export function GeoCriteriaSelector({data, scrape}: { data: ProtectionDTO, scrape: ScrapeDTO }) {
    const {api} = useContext<AxiosContextType>(AxiosContext);
    const defaultValue = data.lastScrape?.geoCriteria?.map(g => g.id)
    const [countries, setCountries] = useState(defaultValue)
    return (<div css={css`display: flex`}>
        <Select mode={"multiple"}
                css={css`width:100%`}
                onChange={(e) => {
                    console.log(e)
                    setCountries([...e])
                }}
                defaultValue={defaultValue}>
            {scrape?.protection?.geoCriteria.map(g => <Select.Option value={g.id}>{g.canonicalName}</Select.Option>)}
        </Select>
        <Button onClick={async () => {
            const geoCriteria = scrape.protection.geoCriteria.filter(g => countries.includes(g.id))
            await api.post("/scrape", {
                ...scrape, id: undefined,
                geoCriteriaToAdd: undefined,
                geoCriteriaToRemove: undefined,
                geoCriteria
            } as ScrapeDTO)
            await api.patch("/rank-threat-handler/protection/" + scrape.protection.id)
        }
        }>
            Apply
        </Button>
    </div>)
}

