/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import '@inovua/reactdatagrid-enterprise/index.css'

import {Button, Select, Spin, Table} from "antd";
import React, {Fragment, useCallback, useContext, useEffect, useState} from "react";
import {AxiosContext, AxiosContextType, getConfig} from "@front-component";
import {useLoading} from "@front-component";
import {ProtectionDTO, ScrapeDTO} from "@backend-lib";

const Style = styled.div`
`


const gridStyle = {minHeight: 550}

export function UsersDashboardPage({type}:{type:"USER"|"PARTNER"|"SUBSCRIBER"}) {
    const {api} = useContext(AxiosContext)
    const [dataSource, setdataSource] = useState(undefined)
    const {doAction, loading} = useLoading()

    const [expandedRows, setExpandedRows] = useState({1: true, 2: true});
    const [collapsedRows, setCollapsedRows] = useState(null);
    const onExpandedRowsChange: any = useCallback(({expandedRows, collapsedRows, data, id, index, rowExpanded}: any) => {
        setExpandedRows(expandedRows);
        setCollapsedRows(collapsedRows);
    }, [])


    const impersonate = (email,type) => async () => {
        await api!.post("/admin/impersonate/" + email)
        if(type=="PARTNER"){
            window.location.href = getConfig().partnerFrontBaseUrl
        }else if(type=="DEFAULT"){
            window.location.href = getConfig().frontBaseUrl
        }
    }
    const deleteOrga = (orgaId) => async () => {
        await api!.delete("/admin/orga/" + orgaId)
        setdataSource(dataSource.filter(p => p.orgaId !== orgaId))
    }

    const columns = [
        {title: 'createdAt',dataIndex: 'createdAt', header: 'Date', defaultWidth: 200},
        {title: 'email', dataIndex:'email', defaultWidth: 200},
        {
            title: 'impersonate', header: 'impersonate', defaultFlex: 5, defaultWidth: 200,
            render: (row) => {
                const impersonateFct = impersonate(row.email,row.type)
                return <Fragment>
                    {row.type == "USER" || row.type=="PARTNER" &&
                    <Button type={"primary"} onClick={impersonateFct}>Impersonate {row.email}</Button>
                    }
                </Fragment>
            }
        },
        {title:'type', dataIndex:'type',header: 'type'},
        {
            title: 'delete', header: 'delete', defaultFlex: 6, defaultWidth: 200,
            render: (row) => {
                if(!row.orgaId){
                    return <Fragment></Fragment>
                }
                const deleteFct = deleteOrga(row.orgaId)
                return <Fragment>{row.type =="USER" && <Button type={"primary"} danger={true} onClick={deleteFct}>Delete Account</Button>}</Fragment>
            }
        },
    ]
    useEffect(() => {doAction(async () => {
        const dashboardData: any = (await api!.get("/admin/user")).data
        setdataSource(dashboardData.map(p => ({
            createdAt: p.createdAt,
            email: p.email,
            accountId: p.account?.id,
            orgaId: p.account?.orga?.id,
            type: (!!p.account?.partnerSubscription?"SUBSCRIBER":(p.account?.orga?.type||"USER"))
        })).filter(p => p.type == type));
    })}, [])

    if (loading) {
        return <Spin/>
    }


    return <Style>
        {dataSource &&
        <Table
            onExpandedRowsChange={onExpandedRowsChange}
            style={gridStyle}
            columns={columns as any}
            dataSource={dataSource}
        />
        }
    </Style>

}

