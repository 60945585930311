import {Empty, Layout, Menu, notification} from "antd";
import {Content} from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
// @ts-ignore
import {useRoutes} from 'hookrouter';
import React from 'react';
import './App.css';
import {DashboardPage} from "./components/page/DashboardPage";
import {FreeCompetitorCheckDashboardPage} from "./components/page/FreeCompetitorCheckDashboardPage";
import {LoginPage} from "./components/page/LoginPage";
import {UsersDashboardPage} from "./components/page/UsersDashboardPage";
import {AxiosContextProvider, handleError, PartnersListRenderer, UserContextProvider} from "@front-component";
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {PartnersListPage} from "./components/page/PartnersListPage";
import {get} from "lodash";
import {cacheAdapterEnhancer, throttleAdapterEnhancer} from "axios-extensions";

function App() {

    const routes = {
        '/': () => <LoginPage/>,
        '/login': () => <LoginPage/>,
        '/user/dashboard': () => <AfterLogin><UsersDashboardPage type={"USER"}/></AfterLogin>,
        '/partner/dashboard': () => <AfterLogin><UsersDashboardPage type={"PARTNER"}/></AfterLogin>,
        '/subscriber/dashboard': () => <AfterLogin><UsersDashboardPage type={"SUBSCRIBER"}/></AfterLogin>,
        '/brand-protection/dashboard': () => <AfterLogin><DashboardPage/></AfterLogin>,
        '/partners': () => <AfterLogin><PartnersListPage/></AfterLogin>,
        '/free-competitor-check/dashboard': () => <AfterLogin><FreeCompetitorCheckDashboardPage/></AfterLogin>,
    };
    const routeResult = useRoutes(routes);
    let api =  createInstance()
    api = configureAxiosInstance(api)

    function createInstance() {
        return axios.create({adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter))});
    }

    function configureAxiosInstance(axiosInstance) {
        axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
            // Do something before request is sent
            config = {
                withCredentials: true,
                baseURL: '/api',
                ...config,
            };

            return config;
        }, (error: Error) => Promise.reject(error));

        axiosInstance.interceptors.response.use((response: AxiosResponse) => {
            return response
        }, (error: AxiosError) => {
            handleError('generic', error);
            if ((get(error, 'response.status') === 401)) {
                if (error.request.responseURL.indexOf('/me') === -1) {
                    if (!window.location.href.includes("login")) {
                        window.location.href = "/login"
                    }
                    return Promise.reject(error);
                }
            } else if (get(error, 'response.status') === 422) {
                return Promise.reject(error);
            } else {
                notification.error({
                    message: 'Oups!',
                    description:
                        'Hmmmm, an error occured!',
                });
                return Promise.reject(error);
            }
            return undefined;
        });
        return axiosInstance;
    }

    return (
        <div className="App">
            <UserContextProvider>
                <AxiosContextProvider api={api}>
                    {routeResult || <Empty/>}
                </AxiosContextProvider>
            </UserContextProvider>
        </div>
    );
}


export default App;

function AfterLogin({children}) {

    async function navigate({key}) {
        window.location.href = "/" + key
    }

    return    <Layout>
        <Layout.Sider width={200} className="site-layout-background">
            <Menu
                mode="inline"
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['sub1']}
                selectedKeys={[window.location.pathname.substring(1)]}
                onClick={navigate}
                style={{height: '100%', borderRight: 0}}
            >
                <Menu.Item key="user/dashboard">Users</Menu.Item>
                <Menu.Item key="partner/dashboard">Partners</Menu.Item>
                <Menu.Item key="subscriber/dashboard">Subscribers</Menu.Item>
                <Menu.Item key="brand-protection/dashboard">Threats</Menu.Item>
                <Menu.Item key="free-competitor-check/dashboard">Free Scrap</Menu.Item>
                <Menu.Item key="partners">Partners</Menu.Item>
            </Menu>
        </Layout.Sider>
        <Layout style={{padding: '0 24px 24px'}}>
            <Content
                className="site-layout-background"
                style={{
                    padding: 24,
                    margin: 0,
                    minHeight: 280,
                }}
            >
                {children}
            </Content>
        </Layout>
    </Layout>
}
