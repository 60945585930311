/** @jsxRuntime classic */
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import '@inovua/reactdatagrid-enterprise/index.css'
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'

import {Button, Select, Spin} from "antd";
import Search from "antd/lib/input/Search";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {AxiosContext, AxiosContextType, GoogleLogo, useLoading} from "@front-component";
import { Fragment } from 'react';
import {KeywordDTO, ProtectionDTO, ScrapeDTO} from "@backend-lib";

const Style = styled.div`
`


const gridStyle = {minHeight: 550}

export function DashboardPage() {
    const {api} = useContext(AxiosContext)
    const [dataSource, setdataSource] = useState(undefined)
    const {doAction, loading} = useLoading()

    const [expandedRows, setExpandedRows] = useState({ 1: true, 2: true });
    const [collapsedRows, setCollapsedRows] = useState(null);
    const onExpandedRowsChange:any = useCallback(({ expandedRows, collapsedRows, data, id, index, rowExpanded }:any) => {
        setExpandedRows(expandedRows);
        setCollapsedRows(collapsedRows);
    }, [])


    const columns = [
        {name: 'account', header: 'Account', defaultFlex: 1},
        {name: 'protection', header: 'Protection', defaultFlex: 1},
        {
            name: 'keywords', header: 'Keywords', defaultFlex: 3,
            render: ({value, data}: { value: any[], data: ProtectionDTO }) => {

                return <Select mode={"multiple"} css={css`width:100%`}
                               defaultValue={value.map(k => k.text)}>
                    {value.map(k => <Select.Option checked={true} value={k.text}>{k.text}</Select.Option>)}
                </Select>
            }
        },
        {
            name: 'lastScrape',
            header: 'Countries'
            , defaultFlex: 6,
            render: ({value, data: data}: { value: ScrapeDTO, data: ProtectionDTO }) => {
                return <GeoCriteriaSelector data={data} scrape={value}/>
            }
        },
    ]
    useEffect(() => {doAction(async () => {
        const protections: ProtectionDTO[] = (await api!.get("/protection")).data
        for (const protection of protections) {
            const protectionsMetrics: any = (await api!.get("/google-ads-api/campaign-metrics/" + protection.id)).data
            protection.metrics = protectionsMetrics
        }

        setdataSource(protections.map(protection => {
            return {
                account: protection.account.googleCustomerId,
                protection: protection.id,
                keywords: protection.keywordsCriteria,
                geoCriteria: protection.geoCriteria,
                lastScrape: protection.lastScrape
            }
        }));
    })}, [])
    if (loading) {
        return <Spin/>
    }

    const renderRowDetails = ({data}) => {
        const lastScrape = data.lastScrape
        return <div style={{padding: 20}}>
            {lastScrape.scrapResults.ads.map(ad=>(<Fragment>
                <div css={css`display: flex;gap:1em; align-items:center`}><GoogleLogo/>
                <Search css={css`width:200px`}/><a href={lastScrape.scrapResults.search_metadata.engine_url}>See on Google</a></div>
                <div css={css`display: flex;gap:1em; align-items:center`}><span
                css={css`font-size:12px;font-weight:bold`}>Ad</span><span
                css={css`font-size:10px`}>{ad.displayUrl}</span>
                </div>
                <a css={css`    color: #1a0dab;    font-size: 12px;
    line-height: 26px;`}>{ad.title}</a>
                <div
                css={css`font-size: 10px;`}> {ad.description}</div>
                </Fragment>
            ))}
        </div>
    };

    return <Style>
        {dataSource &&
        <ReactDataGrid
            idProperty="id"
            renderRowDetails={renderRowDetails}
            rowExpandColumn={true}
            multiRowExpand={true}
            expandedRows={expandedRows}
            collapsedRows={collapsedRows}
            onExpandedRowsChange={onExpandedRowsChange}
            rowExpandHeight={400}
            style={gridStyle}
            columns={columns as any}
            dataSource={dataSource}
        />
        }
    </Style>

}

export function GeoCriteriaSelector({data, scrape}: { data: ProtectionDTO, scrape: ScrapeDTO }) {
    const {api} = useContext<AxiosContextType>(AxiosContext);
    const defaultValue = data.lastScrape?.geoCriteria?.map(g => g.id)
    const [countries, setCountries] = useState(defaultValue)
    return (<div css={css`display: flex`}>
        <Select mode={"multiple"}
                css={css`width:100%`}
                onChange={(e) => {
                    console.log(e)
                    setCountries([...e])
                }}
                defaultValue={defaultValue}>
            {scrape?.protection?.geoCriteria.map(g => <Select.Option value={g.id}>{g.canonicalName}</Select.Option>)}
        </Select>
        <Button onClick={async () => {
            const geoCriteria = scrape.protection.geoCriteria.filter(g => countries.includes(g.id))
            await api!.post("/scrape", {
                ...scrape, id: undefined,
                geoCriteriaToAdd: undefined,
                geoCriteriaToRemove: undefined,
                geoCriteria
            } as ScrapeDTO)
            await api!.patch("/rank-threat-handler/protection/" + scrape.protection.id)
        }
        }>
            Apply
        </Button>
    </div>)
}

