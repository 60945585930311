import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Button, Calendar, DatePicker, Modal, notification, Table} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {EditFilled} from "@ant-design/icons";
import {OrgaDTO, ProtectionDTO, AccountDTO, PartnerAccountAPI} from "@backend-lib";
import {AxiosContext, AxiosContextType} from "../../context";
import {ModalContextReady} from "../ModalContextReady";

interface PartnerTableRow {
    id: string
    key: number
    name: string
    protection?: ProtectionDTO
    subscription: string
    createdAt: Date
    subscriber: string
}

export function PartnersListRenderer() {
    const { api } = useContext<AxiosContextType>(AxiosContext);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [accounts, setAccounts] = useState<AccountDTO[]>([]);
    const [filteredAccounts, setFilteredAccounts] = useState<AccountDTO[]>([]);

    useEffect(() => {
        PartnerAccountAPI.getInstance(api!).getAllAccounts().then((data) => {
            setAccounts(data);
            setFilteredAccounts(data); // Set the filtered accounts initially to all accounts
        });
    }, []);

    useEffect(() => {
        let filtered = accounts;

        if (startDate) {
            filtered = filtered.filter(a => a.createdAt && new Date(a.createdAt) >= startDate);
        }

        if (endDate) {
            filtered = filtered.filter(a => a.createdAt && new Date(a.createdAt) <= endDate);
        }

        setFilteredAccounts(filtered);
    }, [startDate, endDate, accounts]);

    return (
        <Fragment>
            <DatePicker.RangePicker style={{float:"right"}}
                onChange={(values) => {
                    setStartDate(values?.[0]?.toDate() || null);
                    setEndDate(values?.[1]?.toDate() || null);
                }}
            ></DatePicker.RangePicker>
            <PartnersList accounts={filteredAccounts} />
        </Fragment>
    );
}

const downloadBlob = (blob, fileName = 'partners.csv') => {
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.position = 'absolute';
    link.style.visibility = 'hidden';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
};

export function PartnersList({accounts = []}: { accounts: AccountDTO[] }) {

    const {api} = useContext<AxiosContextType>(AxiosContext);
    const [companyToEdit, setCompanyToEdit] = useState<ProtectionDTO | undefined>(undefined)


    const partnersRow = accounts.filter(a => a.partnerSubscription).map((partner: any): PartnerTableRow => {
        const PartnerTableRow: PartnerTableRow = {
            id: partner.id + "",
            key: partner.id,
            name: partner.orga?.customer?.name!,
            subscriber: partner.name!,
            createdAt: new Date(partner.createdAt!),
            subscription: partner.partnerSubscription?.includes("partner_3") ? "Brand protection for 3 keywords" : "Brand protection for 10 keywords"
        }
        return PartnerTableRow
    })


    const columns: ColumnsType<PartnerTableRow> = [
        {
            title: 'Active Date',
            dataIndex: 'createdAt',
            render: (date: Date) => date?.toLocaleDateString(),
        },
        {
            title: 'Subscriber',
            dataIndex: 'subscriber',
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Subscription',
            dataIndex: 'subscription',
            render: (text: string) => <Fragment>{text.includes("partner_3") ? "3 keywords" : "10 keywords"}</Fragment>,
        }

    ];

    const exportCSV = () => {

        const header = columns.map((c) => c.title).join(",");
        const rows = partnersRow.map((data: any) => columns.map((c: any) => data[c.dataIndex]).join(","));

        const contents = [header].concat(rows).join('\n');
        const blob = new Blob([contents], {type: 'text/csv;charset=utf-8;'});

        downloadBlob(blob);
    };

    return (
        <div>
            <Table rowKey="id" dataSource={partnersRow} pagination={{
                defaultPageSize: 20,
            }} columns={columns}/>
            <Button style={{float: "right"}} onClick={exportCSV}>
                Export CSV
            </Button>
        </div>
    );
}
